<template>
  <div class="main-container">
    <div class="top-anchor" id="top-anchor"></div>
    <div class="left-container">
      <InfoPanel :src="src"></InfoPanel>
    </div>
    <div class="right-container">
      <div class="menu-background"></div>
      <Nav class="sticky"></Nav>
      <MenuPanel  v-on:update-image="src=getImgUrl($event)"></MenuPanel>
    </div>
  </div>
</template>

<script>
import InfoPanel from './components/InfoPanel.vue'
import MenuPanel from './components/Menu/MenuPanel.vue'
import Nav from "./components/Nav.vue";

export default {
  name: 'App',
  components: {
    InfoPanel,
    MenuPanel,
    Nav
  },
  data: function() {
    return {
      src: this.getImgUrl("greeting.gif")
    }
  },
  methods: {
    getImgUrl: function (img) {
      return require('./assets/images/'+img)
    }
  }
}
</script>

<style>
#app {
  font-family: Jannon, serif;
  font-size: 16px;
  color: black;
  background-color: #deeac1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Jannon';
  src: url("./assets/fonts/jannon.woff");
}

@font-face {
  font-family: 'Elastik';
  src: url("./assets/fonts/elastik.woff")
}

.caps {
  font-family: Elastik;
}

Nav {
  width: 100%;
  padding: 20px 0 50px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-image: linear-gradient(to bottom, rgba(222,234,193,1), rgba(222,234,193,1), rgba(222,234,193,1), rgba(222,234,193,.8), rgba(222,234,193,.1));
  z-index: 10;
}

html, body, .main-container {
  height: 100%;
  scroll-behavior: smooth;
}

h1 {
  font-weight: normal;
}

body {
  margin: 0;
}

.left-container, .right-container {
  width: 50%;
}

.left-container {
  position: fixed;
  left: 0;
  top: 0;
}

.right-container {
  margin-left: 50%;
}

@media only screen and (max-width: 600px) {
  .main-container{
    display: inline-block;
  }
  .left-container{
    position:static;
    width: 100%;
    padding-bottom: 50px;
  }
  .right-container{
    width: 100%;
    margin: 0;
  }

  .sticky {
    background-image: linear-gradient(to bottom, rgba(222,234,193,1), rgba(222,234,193,1), rgba(222,234,193,1),rgba(222,234,193,1), rgba(222,234,193,1), rgba(222,234,193,.1));
  }
}

@media only screen and (min-width: 1920px) {
  .main-container{
    width: 1920px;
  }
}

</style>