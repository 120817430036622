export const MenuItems = {
    "TACOS.svg": [
        {
            name: "Carnitas",
            price: 3.5,
            description: "Confit pork shoulder",
            image: "Carnitas.jpg",
        },
        {
            name: "Pollo Asada",
            price: 3.5,
            description: "Grilled chicken",
            image: "coming-soon.png",
        },
        {
            name: "Birria",
            price: 3.5,
            description: "Braised beef in mild chiles",
            image: "Birria.jpg",
        },
        {
            name: "Papas",
            price: 3.5,
            description: "Potato, soy chorizo (v)",
            image: "coming-soon.png",
        },
        {
            name: "Sweet Potato",
            price: 3.5,
            description: "Sweet potato, cheese",
            image: "coming-soon.png",
        },
    ],
    "BURRITO.svg": [
        {
            name: "Birria",
            price: 12,
            description: "Beef braised in mild chiles",
            image: "Birria-Burrito.jpg",
        },
        {
            name: "Carnitas",
            price: 12,
            description: "Confit pork shoulder",
            image: "coming-soon.png",
        },
        {
            name: "Pollo Asada",
            price: 12,
            description: "Grilled chicken",
            image: "coming-soon.png",
        },
        {
            name: "Sweet Potato",
            price: 12,
            description: "",
            image: "Sweet-Potato-Burrito.jpg",
        },
        {
            name: "Papas",
            price: 12,
            description: "Potato, soy chorizo",
            image: "coming-soon.png",
        },
        {
            name: "+ Guacamole",
            price: 2,
            image: "coming-soon.png",
        },
    ],
    "PLATES.svg": [
        {
            name: "Quesabirria",
            price: 15,
            description: "3 cheesy braised beef fried tacos and consomme",
            image: "Birria.jpg",
        },
        {
            name: "Pozole",
            price: 9,
            description: "Pork, cominy, avocado, red broth",
            image: "Pozole.jpg",
        },
        {
            name: "Salad",
            price: 9,
            description: "Kale, cilantro lime vinaigrette, avocado, cheese",
            image: "Salad.jpg",
        },
    ],
    "STUFF.svg": [
        {
            name: "Pool Nachos",
            price: 12,
            description: "Chips slathered in nacho cheese, pickled jalapeno, salsa fresca + Protein 3",
            image: "coming-soon.png",
        },
        {
            name: "Tortilla Chips",
            price: 5,
            description: "Sea salt, served with salsa roja",
            image: "Chips.jpg",
        },
        {
            name: "+ Guacamole",
            price: 4,
            description: "Avocado, onion, cilantro, jalapeño, lime",
            image: "coming-soon.png",
        },
        {
            name: "+  Salsa Macha",
            price: 2,
            description: "Dried chile, nuts, seeds, garlic, vinegar, salt, oil",
            image: "Salsa-Macha.jpg",
        },
        {
            name: "Extra Salsa",
            price: 1,
            description: "Spicy, medium, or mild",
            image: "coming-soon.png",
        },
        {
            name: "Dessert Offering",
            description: "Ask Dylan or Cam for info",
            image: "Desert.jpg",
        },
    ]
}
