<template>
  <div class="panel-container">
    <div class="menu-panel">
      <!--  SPECIALS MENU  -->
      <div
        class="sub-panel"
        v-bind:class="specials.length === 0 ? 'menu-false' : 'specials-menu'"
      >
        <ul class="menu specials-menu">
          <li>
            <img
              class="menu-heading"
              :src="getImgUrl('SPECIAL.svg')"
              alt="SPECIAL"
            />
          </li>
          <li
            @mouseover="changeImage(item.image)"
            @click="changeActive('specials', index)"
            class="menu-item"
            v-for="(item, index) in specials"
            :key="item"
          >
            <div class="menu-item-wrapper">
              <div class="item-container">
                <p class="item-name">{{ item.name }}</p>
                <p class="item-desc">{{ item.description }}</p>
              </div>
            </div>
            <div class="collapsible-container">
              <div
                :class="{ contentActive: isActive('specials', index) }"
                class="content"
              >
                <img
                  class="preview-image"
                  :src="getImgUrl(item.image)"
                  :alt="item.image"
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- FOOD MENU -->
      <div class="anchor" id="food-anchor"></div>
      <div v-bind:class="products.length === 0 ? 'bottom-menu' : ''">
        <div class="sub-panel main-menu" ref="main-menu">
          <ul
            class="menu"
            v-bind:class="menuType"
            v-for="(submenu, menuType) in menuItems"
            :key="menuType"
          >
            <li>
              <img class="menu-heading" :src="getImgUrl(menuType)" alt="" />
            </li>
            <li
              v-on:mouseover="changeImage(item.image)"
              @click="changeActive(submenu, index)"
              class="menu-item"
              v-for="(item, index) in submenu"
              :key="item"
            >
              <div class="menu-item-wrapper">
                <div class="item-container">
                  <p class="item-name">{{ item.name }}</p>
                  <p class="item-desc">{{ item.description }}</p>
                </div>
              </div>
              <div class="collapsible-container">
                <div
                  :class="{ contentActive: isActive(submenu, index) }"
                  class="content"
                >
                  <img
                    class="preview-image"
                    :src="getImgUrl(item.image)"
                    :alt="item.image"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="menu-info">
          <p>(v) = vegan</p>
          <p>Order at the bar</p>
          <span>Menu items are </span>
          <span class="caps">GLUTEN-FREE </span>
          <span>but may contain or come into contact with </span>
          <span class="caps">WHEAT, EGGS, PEANUTS, TREE NUTS, and MILK. </span>
          <span>For more information, please speak with </span>
          <span class="caps">DYLAN </span>
          <span>or </span>
          <span class="caps">CAM. </span>
        </div>
      </div>
      <!--PRODUCT MENU-->
      <div class="anchor" id="product-anchor"></div>
      <div
        class="sub-panel bottom-menu"
        v-bind:class="products.length === 0 ? 'menu-false' : 'products-menu'"
      >
        <ul class="menu product-list">
          <li>
            <img
              class="menu-heading"
              :src="getImgUrl('PRODUCT.svg')"
              alt="PRODUCT"
            />
          </li>
          <li
            v-on:mouseover="changeImage(item.image)"
            @click="changeActive('product', index)"
            class="menu-item"
            v-for="(item, index) in products"
            :key="item"
          >
            <div class="menu-item-wrapper">
              <div class="item-container">
                <p class="item-name">{{ item.name }}</p>
                <p class="item-desc">{{ item.description }}</p>
              </div>
            </div>
            <div class="collapsible-container">
              <div
                :class="{ contentActive: isActive('product', index) }"
                class="content"
              >
                <img
                  class="preview-image"
                  :src="getImgUrl(item.image)"
                  :alt="item.image"
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="menu-footer">
      <a
        class="go-back-up caps"
        onclick="document.getElementById('top-anchor').scrollIntoView();"
        >GO BACK UP
        <img class="icon up" src="../../assets/images/up.svg" alt="&#8593;"
      /></a>
    </div>
  </div>
</template>

<script>
import { MenuItems } from "./MenuItems.js";
import { Products } from "./Products.js";
import { Specials } from "./Specials.js";

export default {
  name: "MenuPanel",
  data: () => {
    return {
      menuItems: MenuItems,
      products: Products,
      specials: Specials,
      activeItem: {},
    };
  },
  methods: {
    changeImage: function (imageURL = "greeting.gif") {
      this.$emit("update-image", imageURL);
    },
    getImgUrl: function (imageURL = "coming-soon.png") {
      return require("../../assets/images/" + imageURL);
    },
    changeActive: function (menuType, index) {
      const params = {
        MenuType: menuType,
        Index: index,
      };
      if (this.compareObjects(this.activeItem, params)) {
        this.activeItem = {};
      } else {
        this.activeItem = params;
      }
    },
    isActive: function (menuType, index) {
      return (
        this.activeItem["MenuType"] === menuType &&
        this.activeItem["Index"] === index
      );
    },
    compareObjects: function (obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
  },
  components: {},
};
</script>

<style scoped>
.anchor {
  bottom: 100px;
  position: relative;
}

.menu {
  display: inline-block;
  list-style: none;
  text-align: left;
  padding-left: 10px;
  margin: 0;
  margin-bottom: 40px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.menu-item,
.menu-heading {
  cursor: default;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 16px;
}

.menu-heading {
  height: 30px;
}

.menu-item-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.item-name,
.item-desc {
  margin: 0;
}

.sub-panel {
  padding: 0;
  margin-right: 20px;
  -moz-column-gap: 1.5em;
  -webkit-column-gap: 1.5em;
  column-gap: 1.5em;
  -moz-column-count: 2;
  -webkit-column-count: 2;
  column-count: 2;
}

.menu-info {
  text-align: left;
  padding-left: 10px;
  padding-right: 20px;
  padding-bottom: 40px;
}

.menu-info > span {
  line-height: 19px;
}

.icon {
  height: 22px;
  margin-left: 4px;
}

.menu-false {
  display: none;
}

.bottom-menu {
  min-height: 75vh;
}

.go-back-up {
  font-size: 18px;
  display: inline-block;
  text-align: center;
  margin: 20px auto 20px auto;
  /*margin-bottom: 20px;*/
  cursor: pointer;
  border-bottom: 3px solid transparent;
}

.go-back-up:hover {
  border-bottom: 3px solid black;
}

.menu-footer {
  display: flex;
  align-items: center;
}

.collapsible-container {
  display: none;
}

.preview-image {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.collapsible-icon {
  display: none;
}

@media only screen and (max-width: 880px) {
  .sub-panel {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }

  .anchor {
    bottom: 170px;
  }
}

@media only screen and (max-width: 600px) {
  .menu {
    width: 100%;
    padding-left: 20px;
  }

  .menu-item {
    display: flex;
    flex-direction: column;
  }

  .menu-info {
    padding-left: 20px;
  }

  .content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }

  .contentActive {
    max-height: 500px;
  }

  .menu-item-wrapper {
    cursor: pointer;
  }

  .collapsible-container {
    display: inline-block;
  }

  .collapsible-icon {
    font-size: 20px;
    display: inline-block;
    position: relative;
    bottom: 10px;
    z-index: 0;
  }

  .collapsible-false {
    display: none;
    cursor: default;
  }

  .bottom-menu {
    min-height: 68vh;
  }
}
</style>
