export const Products = [
    {
        name: "Hats",
        price: 45,
        description: "3 feet and rising art on a Nissi washed dad cap in navy",
        image: "coming-soon.png",
    },
    {
        name: "Shirts",
        price: 30,
        description: "Tom & Jerry art on a Gilden Ultra Cotton shirt in heather grey",
        image: "coming-soon.png",
    },
    {
        name: "Beanies",
        price: 30,
        description: "Winter blue Dylan & Cam embroidered logo on a beanie in heather grey",
        image: "Beanie.jpg",
    },
    {
        name: "Hoodie",
        price: 80,
        description: "Winter blue Dylan & Cam embroidered logo on a Champion hoodie in heather grey",
        image: "coming-soon.png",
    },
]